:root {
  --main-bg: rgba(45, 102, 148, 0.849);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

*::-webkit-scrollbar {
  width: 0.7em;
}
 
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--main-bg);
  border: none;
  background-color: var(--main-bg);
}
 
*::-webkit-scrollbar-thumb {
  background-color: #FFFFFF;
  outline: 1px solid slategrey;
  border-radius: 10px;
  width: 100px;
 
}


