* {
    text-transform: none !important;
}
.container {
    display: flex;
    flex-direction: column;
}
.card_container {
    position: relative;
    width: 100%;
    height: 900px;
    /* background-color:rgba(66, 112, 152, 0.214);    */
}
.card { 
    position: absolute;
   left:25vw;
   top:120px;
    transition: 2s;
    animation: translation_mob 15s infinite;
}
.left_card { 
    transform-origin: top; 
   transform-style: preserve-3d;
   animation: rotation_mob 15s infinite;
   /* transform-origin: top; */
   perspective: 1000px;
}
.right_card {   
    background-color: rgba(255, 244, 79);
    box-shadow: inset 0 5px 15px rgba(0,0,0,0.3);
    border-radius: 0 0 20px 20px ;    
    z-index: -1;
 }
 .right_card, .left_card {
    width: 330px;
    height: 400px;    
    transition: 2s; 
    position: absolute;
 }

.outside, .inside {
    width: 330px;
    height: 100%;    
    text-align: center;
    padding: 1.5rem;
}
.outside { 
    width: 330px;   
     box-shadow: inset 0 5px 15px rgba(0,0,0,0.3);
     position: absolute;
     backface-visibility: hidden;
     border-radius: 0 0 20px 20px;
     background: url('https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/hb2.gif?alt=media&token=c43d7d51-8f8e-47a8-8546-82f1e0f6e6c1') no-repeat center center fixed;  
     /* background: url(../assets/Confetti.gif) no-repeat center center fixed;    */
       -webkit-background-size: cover;
       -moz-background-size: cover;
       -o-background-size: cover;
       background-size: cover;  
}
.inside {
    background-color: rgba(255, 244, 79);
    box-shadow: inset 5px 0  15px rgba(0,0,0,0.3);
    transform: rotateX(180deg);
    backface-visibility: hidden;
    border-radius: 20px 20px 0 0;
}
#cake {    
    width: 90%;
    border-radius: 10px;
}
#cover2 {
    margin-top: 3%;
    height: 95%;
    border-radius: 10px; 
    max-width: 320px;
}
.vitalniy_txt {
    height: 48%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: tomato;
    font-weight: bold;
    font-size: xx-large ;
    transform: rotateY(40deg) ;
    font-family: 'Lobster', cursive;
}




.block_container {
    position: relative;
    width: 99vw;
    /* background-color:rgba(176, 166, 186, 0.897);  */
    display: flex;
    flex-direction: column;
    align-items:center;
    margin-bottom: 160px;
}
.block {
 /* position: absolute;
    top: 200px;     */
    perspective: 800px;
    margin-bottom: 180px;
    
}
.block img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transition: 2s;
    transform: scale(1.53);
    z-index: -1;
}

.frames {
    margin-top: 20px;
    width: 400px;
    height: 150px;
    box-shadow: 0 5px 10px rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.8rem;
    transition: 2s;
    transform: rotateY(-30deg) rotateX(10deg);
    border-radius: 20px;
    overflow: hidden; 
}
.frames p {
    font-size: 1rem;
    font-weight: bolder;
    transition: 2s; 
    font-family: 'Lobster', cursive; 
    /* transform: rotateY(180deg); */
}
.frames:hover {   
    transform: scale(1.0) ;
    height: auto;
    min-height: 150px;
    font-size: large;   
    padding-top: 10px;
    position: absolute;   
    z-index: 100;
}
.frames:nth-child(1) {   
    background-color: coral;
}
.frames:nth-child(2) {     
    background-color: goldenrod;
}
.frames:nth-child(3) {     
    background-color: rgb(155, 96, 209);
}
.frames:nth-child(4) {    
    background-color:blueviolet;  
}
.frames:nth-child(5) {    
    background-color:indianred;
}

.frames:nth-child(6) {
    background-color:mediumorchid; 
}
.frames:nth-child(7) {
    background-color:rgb(128, 0, 100); 
}
.frames:nth-child(8) {
    background-color:rgb(0, 128, 85); 
}
.frames:nth-child(9) {
    background-color:rgb(240, 200, 1); 
}
.frames:nth-child(10) {
    background-color:rgb(13, 240, 1); 
}
.frames:nth-child(11) {
    background-color:rgb(224, 1, 240); 
}
.frames:nth-child(12) {
    background-color:green ; 
}
.frames:nth-child(13) {
    background-color: rgb(1, 240, 240); 
}
.frames:nth-child(14) {
    background-color:rgb(169, 18, 153);
}
.frames:nth-child(15) {
    background-color:rgb(240, 192, 1); 
}
.frames:nth-child(16) {
    background-color:rgb(1, 81, 240); 
}
.frames:nth-child(17) {
    background-color:rgb(200, 14, 157);  
}
.frames:nth-child(18) {
    background-color:rgb(1, 20, 128);
}
            .frames:nth-child(18):hover, .frames:nth-child(17):hover {
            padding-bottom: 120px;
            }

@media (min-width: 1300px) {
    .container {
        display: none;      
    }    
}
@media (max-width: 550px) {
    .card { 
     left:10%;     
    }   
}
@media (max-width: 410px) {
    .frames {        
        width: 300px;       
    } 
}
@media (max-width: 360px) {
    .card { 
     left:3%;     
    } 
    .right_card, .left_card {
        width: 270px;       
     }    
    .outside, .inside {
        width: 270px;       
    }
    .outside { 
        width: 270px; 
    }   
}


.txt_container {
    margin-bottom: 10px;
    font-family: 'Lobster', cursive;
}
.btn_container {
   display: flex;
   flex-direction: column;
   align-items:flex-end;
   margin-bottom: 10px;
}
.btn_inline_container {
    display: flex;
    flex-direction: row;
    gap:3px;
}

@keyframes rotation_mob {
    0% {    transform: rotateX(0deg) ;}
    10% {   transform: rotateX(0deg) ;}  
    30% {   transform: rotateX(-180deg) ;} 
    60% {   transform: rotateX(-180deg) ;}
    90% {   transform: rotateX(0deg) ;}  
    100% {  transform: rotateX(0deg) ;  }
}
@keyframes translation_mob {
    0% {    transform: translateY(0px);}
    10% {   transform: translateY(0px);}  
    30% {   transform:  translateY(300px);} 
    60% {   transform: translateY(300px);}
    90% {   transform: translateY(0px);}  
    100% {  transform: translateY(0px); }
}

.frames:last-child {   
    background-color:rgba(128, 0, 100, 0); 
    box-shadow: none;
}