
.volume_box, .volume_box2, .volume_box3 {
    position: fixed;
    bottom: 20px;
    right: 100px;
    cursor: pointer;
    height: 60px;
    width: 60px;
    border-radius: 50%;
   
    display: flex;
    align-items:center;
    justify-content: center;
    
   border: solid 1px;
}
.volume_box2 {
    bottom: 20px;
    right: 20px;
}
.volume_box3 {   
    bottom: 20px;
    right: 184px;   
}
.volume_box:hover, .volume_box2:hover, .volume_box3:hover {
    background-color: rgba(85, 85, 85, 0.708);
}
.turnaround {
    transform: rotateY(180deg)
}
.screen {
    position: fixed;
    bottom: 100px;
    right: 27px;

    background-color:rgba(103, 170, 229, 0.354);
    border-radius:20px;
    width: 210px;
    height: 30px;

    display: flex;
    align-items:center;
    justify-content: center;

    overflow: hidden;
   
}
.run_the_text {
     white-space: nowrap;
     animation: runningText 11.5s infinite linear;
}
.run_the_text:after {
    content: ' -- З Днем Народження! --'
}
@keyframes runningText {
    0% {
        transform: translateX(70%)
    }
    100% {
        transform: translateX(-60%)
    }
}

