.card {
    background-color: rgb(7, 58, 60);;
    width: 100%;
    max-width: 500px;
}

.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(7, 58, 60, 0.897);;
    width: 100%;
    padding: 0;
    margin: 0;
    
}
.container a {
    color: blanchedalmond;
}