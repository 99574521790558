.App {
  text-align: center;
  color: #ffffff;  
}

body {
  background: rgb(58,97,134);
  background: linear-gradient(90deg, rgba(58,97,134,1) 0%, rgba(128,57,75,1) 100%);
  background: url(./assets/LoveCouple.jpg) no-repeat center center fixed;  
  /* background: url(./assets/Happy-Birthday.gif) no-repeat center center fixed;   */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');








