/* * {
    text-transform: none !important;
} */
.container {
    display: flex;
    flex-direction: row;   
    height: 100vh;
    overflow: hidden;
}
.card_container {
    position: relative;
    width: 50vw;
    height: 100vh;
  
}
.card {
    position: absolute;
    left:40%;
    top: 25%;
    transition: 2s;
    animation: translation 15s infinite;
}
.left_card {  
   transform-style: preserve-3d;
   animation: rotation 15s infinite;
   transform-origin: 0 0;
   perspective: 1000px;
}
.right_card {   
    background-color: rgba(255, 244, 79);
    box-shadow: inset 0 5px 15px rgba(0,0,0,0.3);
    border-radius: 0 20px 20px 0;    
    z-index: -1;
 }
 .right_card, .left_card {
    width: 320px;
    height: 400px; 
    position: absolute;
    /* top: 25vh;
    left: 320px; */
    transition: 2s; 
 }



.block_container {
    position: relative;
    width: 50vw;
    height: 100vh;
    overflow-y: scroll; 
   
    padding-bottom: 500px;
    margin-bottom: 200px;
    
}
.block {
    position: absolute;
    top: 200px;
    right: 600px; 
    perspective: 1200px;
}
.block img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transition: 2s;
    transform: scale(1.53);
}
.block img:hover {
   transform: scale(1.9);
   box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.353);
}
.outside, .inside {
    width: 100%;
    height: 100%;    
    text-align: center;
    padding: 1rem;
}
.outside {
     background-color: blanchedalmond;
     box-shadow: inset 0 5px 15px rgba(0,0,0,0.3);
     position: absolute;
     backface-visibility: hidden;
     border-radius: 0 20px 20px 0;
     color:aliceblue;

       background: url('https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/hb2.gif?alt=media&token=c43d7d51-8f8e-47a8-8546-82f1e0f6e6c1') no-repeat center center fixed;  
       /* background: url(../assets/Confetti.gif) no-repeat center center fixed;    */
       -webkit-background-size: cover;
       -moz-background-size: cover;
       -o-background-size: cover;
       background-size: cover;  
}
.outside h1 {
    font-size: 2rem;
    font-weight: bold;
    color: aliceblue;
    margin-bottom: 30px;
  
}
.inside {
    background-color: rgba(255, 244, 79);
    box-shadow: inset 0 5px 15px rgba(0,0,0,0.3);
    transform: rotateY(180deg);
    backface-visibility: hidden;
    border-radius: 20px 0 0 20px;
}
.inside h3 {
    font-size: 1.2rem;
    font-weight: bold;   
    margin-top: 20px;
}
#cake {    
    width: 95%;
    border-radius: 10px;
}
#cover2 {
    margin-top: 5px;    
    max-height: 396px;
    border-radius: 10px; 
    max-width: 310px;
    transition: 2s;
}
#cover2:hover {   
    transform: scale(1.5);
}
.carusel {
    height: 99%;
    display: flex;
    align-items:center;
    justify-content: center;
}
.vitalniy_txt {
    height: 48%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: tomato;
    font-weight: bold;
    font-size: xx-large ;
    transform: rotateY(40deg) ;
    font-family: 'Lobster', cursive;
}


.frames {
    width: 400px;
    height: 150px;
    box-shadow: 0 5px 10px rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.8rem;

    transform: rotateY(-230deg) rotateX(20deg);
    position: absolute;
    border-radius: 20px;
    transition: 2s ease-in-out;

    overflow: hidden;
    cursor: pointer;
    padding-top: 10px;
}

.frames:hover {
    z-index:10000;
    transform: scale(1.5) rotateY(180deg);
    height: auto;
    min-height: 150px;
    padding: 10px; 
    font-size: larger;      
}

.frames p, .frames button {
    font-size: 16px;    
    transform: rotateY(180deg);
} 
.frames p {
    font-family: 'Lobster', cursive;
}
.frames:nth-child(1) {
    top:-160px;   
    background-color: coral;
}
.frames:nth-child(2) { 
    top:-40px;   
    background-color: goldenrod;
}
.frames:nth-child(3) {
    top:70px;  
    background-color: rgb(155, 96, 209);
}
.frames:nth-child(4) {
    top:180px;  
    background-color:blueviolet;
}
.frames:nth-child(5) {
    top:280px;  
    background-color:indianred;
}
.frames:nth-child(6) {
    top:380px;  
    background-color:mediumorchid; 
    padding-top: 30px;
}
.frames:nth-child(7) {
    top:480px;  
    background-color:rgb(128, 0, 100); 
    padding-top: 45px;
}
.frames:nth-child(8) {
    top:560px;  
    background-color:rgb(0, 128, 85); 
}
.frames:nth-child(9) {
    top:640px;  
    background-color:rgb(240, 200, 1); 
}
.frames:nth-child(10) {
    top:720px;  
    background-color:rgb(13, 240, 1); 
}
.frames:nth-child(11) {
    top:800px;  
    background-color:rgb(224, 1, 240); 
}
.frames:nth-child(12) {
    top:870px;  
    background-color:green ; 
}
.frames:nth-child(13) {
    top:970px;  
    background-color: rgb(1, 240, 240); 
}
.frames:nth-child(14) {
    top:1045px;  
    
    background-color:rgb(169, 18, 153);
}
.frames:nth-child(15) {
    top:1120px;  
    
    background-color:rgb(240, 192, 1); 
}
.frames:nth-child(16) {
    top:1190px;  
   
    background-color:rgb(1, 81, 240); 
}
.frames:nth-child(17) {
    top:1260px;  
    
    background-color:rgb(200, 14, 157);  
}
.frames:nth-child(18) {
    top:1330px;  
    background-color:rgb(1, 20, 128);
}
 .frames:nth-child(4):hover {   
    transform: scale(1.6) rotateY(180deg);   
}
            .frames:last-child {
                top:2000px;  
                background-color:rgba(128, 0, 100, 0); 
                box-shadow: none;
            }

.frames:nth-child(17):hover,
.frames:nth-child(16):hover, .frames:nth-child(18):hover{   
    padding-bottom: 12vh;
   
 }

.txt_container {
     transform: rotateY(180deg);
     margin-bottom: 10px;
     font-family: 'Lobster', cursive;
}
.btn_container {
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    margin-bottom: 10px;
}
.btn_inline_container {
    display: flex;
    flex-direction: row;
    gap:20px;
}
.vid_window {
    width: 500px;
    height: 500px; 
}


.frames:last-child {
    top:920px;  
    background-color:rgba(128, 0, 100, 0); 
    box-shadow: none;
}
@media (max-width: 1300px) {
    .container {
       display: none;
    } 
    .frames:hover {  
        padding-left: 0px;     
    }      
}

@media (min-width: 1300px) and (max-width: 1425px) {
    .frames:nth-child(17):hover,
    .frames:nth-child(16):hover, .frames:nth-child(18):hover{  
        padding: 0 50px 80px 10px;
     }
    .frames:hover{  
        padding: 10px 35px 10px 0px;        
     }
}

@keyframes rotation {
    0% {    transform: rotateY(0deg) ;}
    10% {   transform: rotateY(0deg) ;}  
    30% {   transform: rotateY(-180deg) ;} 
    60% {   transform: rotateY(-180deg) ;}
    90% {   transform: rotateY(0deg) ;}  
    100% {  transform: rotateY(0deg) ;  }
}
@keyframes translation {
    0% {    transform: rotate(0deg) translateX(0px);}
    10% {   transform: rotate(0deg) translateX(0px);}  
    30% {   transform: rotate(-3deg) translateX(100px);} 
    60% {   transform:rotate(-3deg) translateX(100px);}
    90% {   transform: rotate(0deg) translateX(0px);}  
    100% {  transform: rotate(0deg) translateX(0px); }
}

